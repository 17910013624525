const startTime = performance.now(); // Tiempo de inicio

//MODULES
// Importamos los módulos ES6
import { initProfile } from './profile.js';
import { initDesktop } from './desktop.js';
import { initContact } from './contact.js';
import { initApps } from './apps.js';
import { initPrompts } from './prompts.js';
import { initHome } from './home.js';

// Evento para DOMContentLoaded
window.addEventListener("DOMContentLoaded", (event) => {
    const domContentLoadedTime = performance.now() - startTime;
    console.log(`DOMContentLoaded took: ${domContentLoadedTime.toFixed(2)}ms`);


});



window.addEventListener('load', (event) => {
    // Inicializamos los módulos que deben cargarse después del DOMContentLoaded
    initProfile();
    initDesktop();
    initContact();
    initApps();
    initPrompts();
    initHome();

    //buscador
    console.log(document.getElementById('search-input'))
    if (document.getElementById('search-input')) {

        console.log('intro')
        const searchInput = document.getElementById('search-input');
        const suggestionsBox = document.getElementById('suggestions');

        searchInput.addEventListener('input', function () {
            const query = searchInput.value;

            // Solo enviar la consulta si hay al menos 2 letras
            if (query.length >= 2) {
                fetch(`/prompts-buscador/?q=${query}`)
                    .then(response => response.json())
                    .then(data => {
                        // Limpiamos el cuadro de sugerencias
                        suggestionsBox.innerHTML = '';
                        console.log(data)

                        // Mostramos las sugerencias
                        if (data.length > 0) {
                            data.forEach(item => {
                                const suggestion = document.createElement('div');
                                suggestion.innerHTML = `<a href="/prompt/${item.slug}/">${item.title}</a>`;
                                suggestionsBox.appendChild(suggestion);
                            });
                            suggestionsBox.style.display = 'block';
                        } else {
                            suggestionsBox.style.display = 'none';
                        }
                    })
                    .catch(error => console.log('Error en la búsqueda:', error));
            } else {
                suggestionsBox.innerHTML = '';
                suggestionsBox.style.display = 'none';
            }
        });
    }

    // buscador imagen

    const searchInputImage = document.getElementById('search-input-image');
    const suggestionsBoxImage = document.getElementById('suggestions-images');

    if (searchInputImage) {
        searchInputImage.addEventListener('input', function () {
            const query = searchInputImage.value;

            // Solo enviar la consulta si hay al menos 2 letras
            if (query.length >= 2) {
                fetch(`/imagenes-generadas/buscador/?q=${query}`)
                    .then(response => response.json())
                    .then(data => {
                        // Limpiamos el cuadro de sugerencias
                        suggestionsBoxImage.innerHTML = '';

                        // Mostramos las miniaturas y los prompts si hay resultados
                        if (data.length > 0) {
                            data.forEach(item => {
                                const suggestion = document.createElement('div');
                                suggestion.classList.add('suggestion-item');
                                suggestion.innerHTML = `
                                        <a href="/imagenes-generadas/${item.slug}"><img src="${item.thumbnail_url}" alt="${item.name}" class="rounded-20"></a>
                                    
                                `;
                                suggestionsBoxImage.appendChild(suggestion);
                            });
                            suggestionsBoxImage.style.display = 'flex';
                        } else {
                            suggestionsBoxImage.style.display = 'none';
                        }
                    })
                    .catch(error => console.log('Error en la búsqueda:', error));
            } else {
                suggestionsBoxImage.innerHTML = '';
                suggestionsBoxImage.style.display = 'none';
            }
        });
    }


    // tooltips
    if (document.getElementById('share-btn')) {
        const shareBtn = document.getElementById('share-btn');
        const tooltip = document.getElementById('share-tooltip');

        // Agregar el listener para mostrar/ocultar el tooltip
        shareBtn.addEventListener('click', function (event) {
            event.preventDefault(); // Prevenir que el enlace recargue la página

            // Toggle el tooltip
            if (tooltip.style.display == 'block') {
                tooltip.style.display = 'none';
            } else {
                tooltip.style.display = 'block';

            }
        });

        // Cerrar el tooltip al hacer clic fuera de él
        document.addEventListener('click', function (event) {
            if (!event.target.closest('.share')) {
                tooltip.style.display = 'none';
            }
        });
    }

    //menu desplegable header
    const userButton = document.querySelector('.user-button');
    const userMenu = document.querySelector('.user-menu');

    if (userMenu) {
        userButton.addEventListener('click', () => {
            const isExpanded = userButton.getAttribute('aria-expanded') === 'true' || false;
            userButton.setAttribute('aria-expanded', !isExpanded);
            userMenu.classList.toggle('open');
        });
    }

    // Cerrar el menú si se hace clic fuera
    document.addEventListener('click', (event) => {
        if (userMenu && !userMenu.contains(event.target) && !userButton.contains(event.target)) {
            userMenu.classList.remove('open');
            userButton.setAttribute('aria-expanded', false);
        }
    });


    // Cerrar la notificación
    if (document.querySelector('.notification')) {
        document.querySelector('#close__notification').addEventListener('click', function (e) {
            document.querySelector('.notification').remove()
        })
    }

    // Copiar confirmacion de correo
    if (document.querySelector('.signup')) {

        const passwordInput = document.getElementById('id_password1');
        const confirmPasswordInput = document.getElementById('id_password2');
        const registerButton = document.getElementById('register-button');
        const registerButtonload = document.getElementById('register-button-load');
        console.log(registerButtonload)
        confirmPasswordInput.type = 'hidden';

        document.getElementById('form-sign').addEventListener('submit', function (event) {
            confirmPasswordInput.value = passwordInput.value;
            registerButton.classList.add('hide')
            registerButtonload.classList.remove('hide')
        });
    }

    
 
    // Acordion
    // Selecciona todos los botones del acordeón
    const accordionButtons = document.querySelectorAll('.accordion-button');
    accordionButtons.forEach(button => {
        button.addEventListener('click', function () {
            // Obtiene el elemento objetivo que va a colapsar o expandirse
            const targetId = button.getAttribute('data-target');
            const collapseElement = document.querySelector(targetId);
            // Si el elemento ya está expandido, lo colapsa
            if (button.classList.contains('collapsed')) {
                button.classList.remove('collapsed');
                button.setAttribute('aria-expanded', 'true');
                collapseElement.style.maxHeight = collapseElement.scrollHeight + "px";

            } else {
                // Si el elemento está colapsado, lo expande
                button.classList.add('collapsed');
                button.setAttribute('aria-expanded', 'false');
                collapseElement.style.maxHeight = 0;
            }

            // Rotar el icono
            const icon = button.querySelector('.icon');
            icon.classList.toggle('fa-angle-down');
            icon.classList.toggle('fa-angle-up');
        });
    });

    //performance
    const loadTime = performance.now() - startTime; // Tiempo desde que comenzó la carga hasta load
    console.log(`Load event took: ${loadTime.toFixed(2)}ms`);

    // menu mobile
    if(document.getElementById('menuToggle')) {
        document.getElementById('menuToggle').addEventListener('click', function() {
            var menu = document.getElementById('navbarMenu');
            menu.classList.toggle('active');
        });
    
    }
    

});

