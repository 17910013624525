export function initHome() {
    console.log("home module initialized");

    if (document.querySelector('.home')) {

        const slider = document.querySelector('.grid-inline');
        let isDown = false;
        let startX;
        let scrollLeft;
        let scrollAmount = 0;
        let maxScrollLeft = slider.scrollWidth - slider.clientWidth;
        let direction = 1; // 1 para derecha, -1 para izquierda
        const scrollSpeed = 2; // Ajusta la velocidad del scroll
        let isAutoScrolling = true; // Control para saber si está en modo auto scroll
        let autoScrollInterval;

        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
            clearInterval(autoScrollInterval); // Detenemos el auto scroll cuando se arrastra manualmente

        });

        slider.addEventListener('mouseenter', () => {
            clearInterval(autoScrollInterval); // Detenemos el auto scroll cuando se arrastra manualmente

        });

        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
            startAutoScroll();

        });

        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
        });

        slider.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 2; // Ajusta el valor para hacer el desplazamiento más o menos rápido
            slider.scrollLeft = scrollLeft - walk;
        });


        function autoScroll() {
            if (isAutoScrolling) {
                if (scrollAmount >= maxScrollLeft) {
                    direction = -1;
                } else if (scrollAmount <= 0) {
                    direction = 1;
                }

                slider.scrollLeft += scrollSpeed * direction;
                scrollAmount = slider.scrollLeft;

            }
        }

        // Iniciar el auto scroll
        function startAutoScroll() {
            autoScrollInterval = setInterval(autoScroll, 1); // Inicia el auto scroll
        }

        // Iniciar el auto scroll al cargar la página
        setTimeout(() => {
            startAutoScroll();

        }, 100);

        // Actualizar el valor de maxScrollLeft si cambian las dimensiones
        window.addEventListener('resize', () => {
            maxScrollLeft = slider.scrollWidth - slider.clientWidth;
        });
    }


    /// efecto maquina de escribir
    class TypeWriter {
        constructor(element, options = {}) {
            this.element = element;
            this.textArray = options.textArray || [];
            this.loop = options.loop || false;
            this.typeSpeed = options.typeSpeed || 100; // Velocidad de tipeo por letra
            this.deleteSpeed = options.deleteSpeed || 50; // Velocidad de borrado por letra
            this.delayBetweenWords = options.delayBetweenWords || 1000; // Pausa entre palabras
            this.pauseBetweenPhrases = options.pauseBetweenPhrases || 2000; // Pausa después de cada frase
            this.currentIndex = 0; // Índice actual del array de frases
            this.isDeleting = false;
            this.text = ''; // Texto actual mostrado
            this.cursorChar = options.cursorChar || '|'; // El caracter del cursor (por defecto |)
            this.cursorType = options.cursorType || 'static'; // 'none', 'static', 'blinking'
            this.injectStyles(); // Inyecta estilos CSS desde JS
            this.startTyping(); // Inicia el proceso
        }
    
        async startTyping() {
            while (true) {
                const currentText = this.textArray[this.currentIndex];
                if (!this.isDeleting) {
                    await this.type(currentText); // Escribe la frase actual
                    await this.delay(this.pauseBetweenPhrases); // Pausa antes de borrar la frase
                    this.isDeleting = true; // Cambia al modo de borrado
                } else {
                    await this.delete(currentText); // Borra la frase actual
                    await this.delay(this.delayBetweenWords); // Pausa antes de escribir la siguiente
                    this.isDeleting = false;
                    this.currentIndex = (this.currentIndex + 1) % this.textArray.length; // Cambia a la siguiente frase
                    if (!this.loop && this.currentIndex === 0) break; // Si no está en loop, termina después de una iteración completa
                }
            }
        }
    
        type(text) {
            return new Promise((resolve) => {
                let charIndex = 0;
                const typeInterval = setInterval(() => {
                    // Escribe letra por letra y añade el cursor según el tipo seleccionado
                    this.text = text.slice(0, charIndex + 1) + this.getCursor();
                    this.updateElement(); // Actualiza el texto mostrado
                    charIndex++;
                    if (charIndex === text.length) {
                        clearInterval(typeInterval);
                        resolve(); // Resuelve la promesa cuando termina de escribir
                    }
                }, this.typeSpeed);
            });
        }
    
        delete(text) {
            return new Promise((resolve) => {
                let charIndex = text.length;
                const deleteInterval = setInterval(() => {
                    // Borra letra por letra y añade el cursor según el tipo seleccionado
                    this.text = text.slice(0, charIndex - 1) + this.getCursor();
                    this.updateElement(); // Actualiza el texto mostrado
                    charIndex--;
                    if (charIndex === 0) {
                        clearInterval(deleteInterval);
                        resolve(); // Resuelve la promesa cuando termina de borrar
                    }
                }, this.deleteSpeed);
            });
        }
    
        getCursor() {
            if (this.cursorType === 'none') {
                return ''; // Sin cursor
            } else if (this.cursorType === 'static') {
                return this.cursorChar; // Cursor fijo
            } else if (this.cursorType === 'blinking') {
                return `<span class="blinking-cursor">${this.cursorChar}</span>`; // Cursor titilante
            }
        }
    
        updateElement() {
            // Actualiza el contenido del elemento con el texto y el cursor
            this.element.innerHTML = `<span>${this.text}</span>`;
        }
    
        delay(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }
    
        injectStyles() {
            // Crea el estilo para el cursor titilante y lo inyecta en el DOM
            const style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = `
                .blinking-cursor {
                    display: inline-block;
                    animation: blink 1s steps(2, start) infinite;
                }
                @keyframes blink {
                    to {
                        visibility: hidden;
                    }
                }
            `;
            document.head.appendChild(style);
        }
    }
    
    // Inicializar el TypeWriter con múltiples frases, cursor titilante y pausa entre frases
    if(document.querySelector('.typewriter')) {
        const element = document.querySelector('.typewriter');
        const typeWriter = new TypeWriter(element, {
            textArray: ['La biblioteca definitiva de prompts para IA', 'La biblioteca más extensa de prompts para IA'],
            loop: true, // Cambia indefinidamente entre las frases
            typeSpeed: 50, // Velocidad de tipeo
            deleteSpeed: 10, // Velocidad de borrado
            delayBetweenWords: 20, // Pausa antes de escribir la siguiente frase
            pauseBetweenPhrases: 6000, // Pausa entre frases (después de escribir)
            cursorChar: '|', // Caracter del cursor
            cursorType: 'blinking' // Opciones: 'none', 'static', 'blinking'
        });
        
    }
   
    
}
